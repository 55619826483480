<template>
  <div class="form">
    <div class="form-inner">
      <h2>输入你的 账号和密码</h2>
      <div class="form-item">
        <el-input prefix-icon="el-icon-user" v-model="account" placeholder="用户账号" autocomplete="off"
          :input-style="inputStyle"></el-input>
      </div>
      <div class="form-item">
        <el-input prefix-icon="el-icon-lock" v-model="password" :type="passwordShow ? 'text' : 'password'"
          placeholder="密码" autocomplete="new-password" :input-style="inputStyle" @keypress.native.enter="submitLogin">
          <span style="cursor: pointer" slot="suffix">
            <i class="el-input__icon el-icon-remove-outline" v-if="passwordShow"
              @click="passwordShow = !passwordShow"></i>
            <i class="el-input__icon el-icon-view" v-if="!passwordShow" @click="passwordShow = !passwordShow"></i>
          </span>
        </el-input>
      </div>
      <el-button type="primary" class="btn" size="large" @click="submitLogin">登录
      </el-button>
      <div class="form-item reg-router" v-if="showRegister">
        尚未注册? <span class="register-btn" @click="goRegister">立即注册</span>新的 账号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  props: {
    showRegister: {
      type: Boolean,
      default: true,
    },
    navigator: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputStyle: { height: "36px", lineHeight: "36px", borderRadius: "4px" },
      account: "",
      password: "",
      passwordShow: false,
    };
  },
  methods: {
    submitLogin() {
      this.$post("/users/login", {
        account: this.account,
        password: this.password,
      }).then((res) => {
        if (res.code != 200) {
          return this.$message.error(res.message);
        }
        this.$store.dispatch("userLogin", res.data).then((data) => {
          if (this.navigator) {
            this.$router.push(this.$route.query.redirect || "/");
          }
        });

        this.account = "";
        this.password = "";
      });
    },
    goRegister() {
      this.$router.push({
        path: "/register",
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 410px;
  display: flex;
  flex-direction: column;

  .form-inner {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    margin: 5px 0 40px;
    color: #222;
  }

  .form-item {
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 4px;

    /deep/.el-input__inner {
      background-color: transparent;
      border-color: #eaeaea;
      color: #333;
    }
  }

  .btn {
    width: 100%;
    margin-bottom: 30px;

    &:hover {
      opacity: 0.8;
    }
  }

  .reg-router {
    padding: 0 10px;
    color: #c5c7cc;
    background-color: #fafafa;
    font-size: 14px;
    line-height: 40px;
    margin-bottom: 0;

    .register-btn {
      color: #5fc7bc;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
